var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"id":"search","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"検索","hint":"顧客名、読み仮名、短縮表示名で検索可能です"},nativeOn:{"change":function($event){return _vm.searchFirst()}},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}})],1),_c('v-col',{staticClass:"pl-4",attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.userList,"label":"テクネ担当者一覧","clearable":""},on:{"change":function($event){return _vm.searchFirst()}},model:{value:(_vm.params.customer_user),callback:function ($$v) {_vm.$set(_vm.params, "customer_user", $$v)},expression:"params.customer_user"}})],1),_c('v-col',{staticClass:"pl-4",attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.status_items,"hide-details":"false","label":"インボイス登録状況"},on:{"change":function($event){return _vm.searchFirst()}},model:{value:(_vm.params.registration_status),callback:function ($$v) {_vm.$set(_vm.params, "registration_status", $$v)},expression:"params.registration_status"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customerList,"items-per-page":-1,"hide-default-footer":"","fixed-header":"","height":_vm.list_height + 'px'},on:{"dblclick:row":function ($event, ref) {
	var item = ref.item;

	return _vm.rowClick(item);
}},scopedSlots:_vm._u([{key:"item.branch_name",fn:function(ref){
var item = ref.item;
return _vm._l((item.branch),function(i,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(i.short_name)+" ")])})}},{key:"item.postalcode",fn:function(ref){
var item = ref.item;
return _vm._l((item.branch),function(i,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(i.postalcode || "ー")+" ")])})}},{key:"item.address",fn:function(ref){
var item = ref.item;
return _vm._l((item.branch),function(i,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(i.prefecture + i.address1 + (item.address2 || ""))+" ")])})}}],null,true)}),_c('div',{staticClass:"text-center pt-3",attrs:{"id":"pagination"}},[_c('v-pagination',{attrs:{"length":_vm.pagination.last_page},on:{"input":function($event){return _vm.paramsToQuery()}},model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }